const Modal = ({ children, title, className, onClose, buttons }) => {

  const renderButton = ({ label, onClick, className })=>{
    return (
      <button onClick={onClick} className={`button button--long  ${className}`}>
        {label}
      </button>
    )
  }

  return (
    <div className={`Modal ${className}`}>
      <h2 className="Modal__title">
        {title}
      </h2>

      {onClose && (<span
        className="Modal__close-button"
        onClick={onClose}
        >
          &times;
        </span>
      )}

      <div className="Modal__content">
        {children}

        {buttons && (
          <div className="Modal__content-buttons">
            {buttons.map(renderButton)}
          </div>
        )}

      </div>

    </div>
  )
}

export default Modal;
