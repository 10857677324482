import React, { useState, useRef, useEffect } from "react";
import Webcam from "react-webcam";

const Camera = ({ parentSetRef, elementHeight }) => {
  const useWindowSize = () => {
    const [windowSize, setWindowSize] = useState({
      width: elementHeight,
      height: elementHeight
    });

    useEffect(() => {
      function handleResize() {
        if (elementHeight) {
          setWindowSize({
            width: window.innerWidth,
            height: window.innerHeight
          });
        }
      }
      window.addEventListener("resize", handleResize);
      handleResize();
      return () => window.removeEventListener("resize", handleResize);
    }, []);
    return windowSize;
  };
  const size = useWindowSize();

  console.log(size);

  const ratio = 1;

  const webcamRef = useRef(null);

  const videoConstraints = {
    facingMode: "user",
    aspectRatio: ratio
  };

  parentSetRef(webcamRef);

  return (
    <Webcam
      className="SignupImagePage__video"
      audio={false}
      height={size.height}
      width={size.width}
      ref={webcamRef}
      mirrored={true}
      screenshotFormat="image/jpeg"
      videoConstraints={videoConstraints}
    />
  );
};

export default Camera;
