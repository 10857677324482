import I18n from "../../../ui/I18n";
import instructions_tv from "../../../../images/instructions_tv.png";
import instructions_question from "../../../../images/instructions_question.png";

const Instructions = () => {
  return (
    <div className="InstructionsPage">
      <div className="InstructionsPage__title">
        <I18n t="client.instructions.title" />
      </div>
      <div className="InstructionsPage__content">
        <I18n t="client.instructions.content" />
      </div>
      <img className="InstructionsPage__image" src={instructions_tv}></img>
      <div className="InstructionsPage__content">
        <I18n t="client.instructions.description_one" />
      </div>
      <img
        className="InstructionsPage__image"
        src={instructions_question}
      ></img>
      <div className="InstructionsPage__content">
        <I18n t="client.instructions.description_two" />
      </div>
      <div className="InstructionsPage__footer">
        <I18n t="client.instructions.footer" />
      </div>
    </div>
  );
};

export default Instructions;
