import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { onFocus, onBlur } from "./stateMachine";

import Home from "./pages/Home";
import Waiting from "./pages/Waiting";
import Final from "./pages/Final";
import Game from "./pages/Game";
import InitialsForm from "./pages/signup/InitialsForm";
import AvatarSelection from "./pages/signup/AvatarSelection";
import ImageForm from "./pages/signup/ImageForm";
import StepTransition from "../../ui/StepTransition";
import ShowSelfie from "./pages/signup/ShowSelfie";
import ShowAvatar from "./pages/signup/ShowAvatar";
import Survey from "./pages/Survey";
import Info from "./pages/Info";
import Sorry from "./pages/Sorry";
import Instructions from "./pages/Instructions";

const stepTransitionMapper = {
  "null:home": "none",
  "home:home": "static",

  "null:sorry": "none",
  "sorry:sorry": "static",
  "home:sorry": "fade",

  "home:signup_initials": "slideLeft",
  "signup_initials:signup_initials": "static",

  "signup_initials:signup_image": "slideLeft",
  "signup_image:signup_image": "static",

  "signup_image:signup_avatar": "slideLeft",
  "signup_avatar:signup_avatar": "static",
  "signup_avatar:signup_image": "slideRight",

  "signup_avatar:show_avatar": "crossfade",
  "show_avatar:signup_avatar": "slideRight",
  "show_avatar:show_avatar": "static",

  "signup_image:signup_selfie": "crossfade",
  "signup_selfie:signup_selfie": "static",
  "signup_selfie:signup_image": "slideRight",

  "signup_selfie:instructions": "slideLeft",
  "signup_avatar:instructions": "slideLeft",

  "show_avatar:instructions": "slideLeft",

  "instructions:instructions": "static",
  "instructions:question": "slideDown",

  "question:question": "static",
  "question:final": "crossfade",

  "final:final": "static",
  "final:home": "static",

  "final:survey": "slideLeft",
  "survey:survey": "static",

  "survey:info": "slideDown",
  "info:info": "static",
  "info:survey": "slideRight"
};

const StateRouter = ({ subscribe }) => {
  const dispatch = useDispatch();
  const [previousStep, setPreviousStep] = useState("null");

  let currentStep = useSelector((state) => state.stateMachine.step);
  const lang = useSelector((state) => state.stateMachine.lang);

  // const timerTick = () => dispatch(emitTick())

  if (!lang) {
    currentStep = "home";
  }

  useEffect(() => {
    if (!window._attachedEvents) {
      window.addEventListener("blur", () => dispatch(onBlur()));
      window.addEventListener("focus", () => dispatch(onFocus()));
      window._attachedEvents = true;
    }
    // if(!window.controlTimer) {
    //   window.controlTimer = setInterval(() => {
    //     timerTick();
    //   }, 800);
    // }
  });

  useEffect(() => {
    if (previousStep !== currentStep) {
      setTimeout(() => {
        setPreviousStep(currentStep);
      }, 500);
    }
  }, [currentStep]);

  const stepComponents = {
    null: <></>,
    home: <Home />,
    signup_initials: <InitialsForm />,
    signup_image: <ImageForm />,
    signup_avatar: <AvatarSelection />,
    signup_selfie: <ShowSelfie />,
    show_avatar: <ShowAvatar />,
    waiting: <Waiting />,
    question: <Game />,
    final: <Final />,
    survey: <Survey />,
    info: <Info />,
    sorry: <Sorry />,
    instructions: <Instructions />
  };

  const animation =
    stepTransitionMapper[`${previousStep}:${currentStep}`] || "none";

  console.log(`${previousStep}:${currentStep} -> ${animation}`);

  const animationEnd = () => {
    setPreviousStep(currentStep);
  };

  return (
    <StepTransition
      animation={animation}
      onAnimationEnd={animationEnd}
      onTransitionEnd={animationEnd}>
      <div className="previous">
        {previousStep !== currentStep && stepComponents[previousStep]}
      </div>

      <div className="current">{stepComponents[currentStep]}</div>
    </StepTransition>
  );
};

export default StateRouter;
