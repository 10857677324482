import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { decreaseTimer } from "../stateMachine";


const Timer = () => {
  // const dispatch = useDispatch();

  const {leftTime} = useSelector(state => state.stateMachine.countdown);
  const step = useSelector(state => state.stateMachine.step);

  // const decTimer = () => dispatch(decreaseTimer())

  // useEffect(() => {
  //   if(!window.controlTimer) {
  //     window.controlTimer = setInterval(() => {
  //       decTimer();
  //     }, 1000);
  //   }
  // });

  return (
    <div className="Timer">
      <button
        className={`button button--long state`}
        disabled={true}
      >
        {step}: {leftTime}
      </button>
    </div>
  )
}

export default Timer;
