import { useSelector } from "react-redux";

const Timer = () => {
  const countdown = useSelector((state) => state.stateMachine.countdown);
  const timeFraction = countdown.leftTime / countdown.total;

  let remainingTimeClass = "info";
  if (timeFraction < 0.25) {
    remainingTimeClass = "alert";
  } else if (timeFraction < 0.5) {
    remainingTimeClass = "warning";
  }

  return (
    <div className="Timer">
      <svg
        className="Timer__svg"
        viewBox="0 0 100 100"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g className="Timer__circle">
          <circle
            className="Timer__path-elapsed"
            cx="50"
            cy="50"
            r="45"
          ></circle>
          <path
            id="Timer-path-remaining"
            strokeDasharray={`${(283 * timeFraction).toFixed()} 283`}
            className={`Timer__path-remaining ${remainingTimeClass}`}
            d="
              M 50, 50
              m -45, 0
              a 45,45 0 1,0 90,0
              a 45,45 0 1,0 -90,0
            "
          />
        </g>
      </svg>
      <span id="Timer-label" className="Timer__label">
        {countdown.leftTime}
      </span>
    </div>
  );
};

export default Timer;
