import React, { useEffect, useRef } from "react";
import I18n from "../../../../ui/I18n";
import Camera from "../helpers/Camera";

const TakeSelfie = ({
  error,
  setRef,
  buttons,
  activeButtonId,
  handleButtonClick,
  capture
}) => {
  const elementRef = useRef(null);
  useEffect(() => {
    console.log(elementRef.current?.clientHeight);
  });

  return (
    <div className="SignupImagePage">
      {error && (
        <div className="SignupImagePage__error">
          <I18n t={`client.signup.errors.${error}`} />
        </div>
      )}
      <div className="SignupImagePage__header">
        <span>
          <I18n t="client.signup.header.selfie" />
        </span>
      </div>
      <div ref={elementRef} className="SignupImagePage__mask">
        <Camera
          elementHeight={elementRef.current?.clientHeight}
          parentSetRef={setRef}
        />
      </div>
      <div className="SignupImagePage__container">
        <div className="SignupImagePage__buttons">
          <button
            key={buttons[0].id}
            variant="dark mt-3 mr-3 "
            className={
              buttons[0].id === activeButtonId
                ? " choiceButton"
                : "is_active choiceButton choiceButton--transparent"
            }
            value={buttons[0].id}>
            <I18n t={`client.signup.button.${buttons[0].title}`} />
          </button>
          <button
            key={buttons[1].id}
            variant="dark mt-3 mr-3 "
            className={
              buttons[1].id === activeButtonId
                ? " choiceButton"
                : "is_active choiceButton choiceButton--transparent"
            }
            value={buttons[1].id}
            onClick={handleButtonClick}>
            <I18n t={`client.signup.button.${buttons[1].title}`} />
          </button>
        </div>
        <button className="captureButton" onClick={capture}></button>
        <div className="SignupImagePage__footer"></div>
      </div>
    </div>
  );
};

export default TakeSelfie;
