import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  step: 'home',
  stats: [],
  connections: 0,
  winners: [],
  connected: false,
  countdown: {
    display: false,
    leftTime: 0,
    total: 5,
  },
  players: [],
  reviewedPlayers: []
}

export const stateMachine = createSlice({
  name: 'stateMachine',
  initialState,
  reducers: {
    onMessage: (state, { payload: { type, param } }) => {
      console.log(type, JSON.stringify({type:'stateMachine/onMessage', payload: { type, param }}));

      switch(type){
        case "state":
          state.step = param;

        case "countdown":
          state.countdown = {
            ...state.countdown,
            ...param,
            display: param['leftTime'] !== 0
          };
          break;
        case 'players':
          state.players = param;
          break;

        default:
          console.log(`Unsuported: ${type}`);
      }
    },
    onDisconnection: (state, params) => {
      state.connected = false

      window._WSConnection.io.connect();
    },
    onConnection: (state, params) => {
      state.connected = true
    },
    sendMessage(state, { payload: { type, message } }) {
      window._WSActions.sendMessage(type, message);

      state.timerDuration = 0;
      state.timer = false;
      // clearInterval(window.controlTimer);
    },
    reviewPlayer(state, { payload }){
      state.reviewedPlayers = [...new Set([
        ...state.reviewedPlayers,
        payload
      ])]
      console.log(state.reviewedPlayers)
    }
  },
})

// Action creators are generated for each case reducer function
export const {
  onMessage,
  onDisconnection,
  onConnection,
  sendMessage,
  decreaseTimer,
  reviewPlayer
} = stateMachine.actions;

export const actions = stateMachine.actions;

export default stateMachine.reducer;
