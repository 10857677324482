import enCA from './en-CA.json';
import frCA from './fr-CA.json';

const transtlations = {
  "en-CA": enCA,
  "fr-CA": frCA,
};


export default transtlations;
