import { useSelector } from "react-redux";
import Indicator from "./Indicator";

const Icon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
    <path fill="currentColor" d="M512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256z" />
  </svg>
);

const ConnectionIndicator = ({ onClick, hidePositive = false }) => {
  const connected = useSelector((state) => state.stateMachine.connected);

  return (
    <Indicator
      status={connected}
      positive={hidePositive ? "" : <Icon />}
      negative={(<Icon />)}
      onClick={onClick}
    />
  );
};

export default ConnectionIndicator;
