// signup phase 97
// - video 22
// - signup 70
// - countdown 5
// lightshow grace 15
// lightshow 15
// winnerslight 5

const OFFLINE_TIMELINE = {
  'home': {
    timeout: 91, //  92 seconds
    destinationStep: 'sorry'
  },
  'signup': {
    timeout: 91, //  92 seconds
    destinationStep: 'sorry'
  },
  'signupForm': {
    timeout: 91, //  92 seconds
    destinationStep: 'waiting'
  },
  'waiting': {
    timeout: 91, //  92 seconds
    destinationStep: 'countdown'
  },
  'countdown': {
    timeout: 96, //  97 seconds
    destinationStep: 'lightshow'
  },
  'lightshow': {
    timeout: 111, //  112 seconds
    destinationStep: 'blackscreen'
  },
  'blackscreen': {
    timeout: 131, //  132 seconds
    destinationStep: 'loser'
  },
  'winnerslight': {
    timeout: 131, //  132 seconds
    destinationStep: 'winner'
  },
  'winner': {
    timeout: -1,
    destinationStep: ''
  },
  'loser': {
    timeout: -1,
    destinationStep: ''
  },
  'sorry': {
    timeout: -1,
    destinationStep: ''
  }
}

const offlineState = (step, startedAt)=> {
  const {
    timeout,
    destinationStep
  } = OFFLINE_TIMELINE[step];

  if(timeout === -1){ return false; }

  const elapsedTime = parseInt(((new Date()) - (new Date(startedAt)) ) / 1000);
  console.log('OFFLINE',elapsedTime, destinationStep);

  if(elapsedTime > timeout) {
    return destinationStep;
  }

  return false;
}

export {
  offlineState
}
