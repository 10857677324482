const Indicator = ({ status, positive, negative, onClick }) => {
  let enableClass = "disabled";
  let content = negative;

  if (status) {
    enableClass = "enabled";
    content = positive;
  }

  return (
    <div
      onClick={onClick}
      className={`Indicator Indicator--${enableClass}`}
    >
      {content}
    </div>
  );
};

export default Indicator;
