import { HashRouter, Route } from "react-router-dom";

const Router = ({ routes }) => {
  const renderRoutes = () => {
    return routes.map((route, index) => {
      return <Route key={`route-${index}`} {...route} />;
    });
  };

  return <HashRouter>{renderRoutes()}</HashRouter>;
};

export default Router;
