import { useState, useEffect } from "react";
import I18n from "../../../ui/I18n";
import Timer from "./Timer";
import { useSelector, useDispatch } from "react-redux";
import { applyAnswer } from "../stateMachine";
import Waves from "../../../ui/Waves";
import AnswerIndicator from "../../../ui/AnswerIndicator";

const Question = () => {
  const selectedQuestion = useSelector(
    (state) => state.stateMachine.selectedQuestion
  );
  const dispatch = useDispatch();
  const [isAnswered, setIsAnswered] = useState(null);

  const lang = useSelector((state) => state.stateMachine.lang);
  const questionIndex = useSelector(
    (state) => state.stateMachine.questionIndex
  );
  const options = useSelector((state) => state.stateMachine.options);
  const roundState = useSelector((state) => state.stateMachine.roundState);
  const initials = useSelector((state) => state.stateMachine.initials);
  const hash = useSelector((state) => state.stateMachine.hash);
  const roundScore = useSelector((state) => state.stateMachine.roundScore);
  const correct = useSelector((state) => state.stateMachine.correct);
  const score = useSelector((state) => state.stateMachine.score);
  const correctAnswerIndex = useSelector(
    (state) => state.stateMachine.correctAnswerIndex
  );

  const shouldShowOptions = ["answering", "round_score"].includes(roundState);

  useEffect(() => {
    if (roundState === "announcement") {
      setIsAnswered(false);
    } else if (roundState === "round_score") {
      setIsAnswered(true);
    }
  }, [roundState]);

  const handleAnswerClick = (answerIndex) => {
    if (!selectedQuestion) {
      dispatch(
        applyAnswer({
          answerIndex,
          initials,
          roundIndex: questionIndex,
          contestID: hash,
        })
      );
    }
  };

  const renderRoundScore = () => {
    return (
      <div className="QuestionPage__score score">
        <div className="QuestionPage__score-round">+{roundScore}</div>
        <div className="QuestionPage__score-total">Total {score} pts</div>
      </div>
    );
  };

  const renderCountdown = () => {
    return (
      <div className="timer">
        <Timer />
      </div>
    );
  };

  const renderOptions = () => {
    return (
      <div className={`QuestionPage__options options`}>
        <div>
          {options.map((option) => (
            <div
              key={option.index}
              className={`QuestionPage__options-item 
              ${getQuestionBoxModifier(option.index)} 
              ${getCorrectAnswerIndicator(option.index)}`}
              onClick={() => handleAnswerClick(option.index)}
            >
              {option.title[lang]}
            </div>
          ))}
        </div>
      </div>
    );
  };

  const getQuestionBoxModifier = (questionIndex) => {
    if (questionIndex !== selectedQuestion) {
      return "";
    }

    if (roundState === "answering") {
      return "selected";
    }

    if (correct) {
      return "correct";
    } else {
      return "wrong";
    }
  };

  const getCorrectAnswerIndicator = (selectedIndex) => {
    if (isAnswered) {
      if (selectedIndex === correctAnswerIndex) {
        return "correct";
      } else {
        return "";
      }
    }
    return "";
  };

  return (
    <div className={`QuestionPage__container ${roundState}`}>
      {isAnswered && <AnswerIndicator />}

      <div className="QuestionPage__title title">
        <I18n
          t="client.game.round.question"
          params={{ index: questionIndex }}
        />

        <div className={`QuestionPage__sub-title instruction`}>
          <I18n t="client.game.round.announcement" />
        </div>
      </div>

      <div className="QuestionPage__content">
        {renderCountdown()}
        {renderRoundScore()}
      </div>

      {renderOptions()}
    </div>
  );
};

export default Question;
