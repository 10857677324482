import Router from "./Router";
import Client from "../workflows/Client";
import Admin from "../workflows/Admin";

const routes = [
  {
    path: "/:hash/",
    component: Client,
    exact: true,
  },
  {
    path: "/a/:hash/",
    component: Admin,
    exact: true,
  },
];

function Layout() {
  return <Router routes={routes} />;
}

export default Layout;
