import { configureStore } from "@reduxjs/toolkit";
import { contestStatus } from "./api";

const RESETABLE_STATES = ["home", "signup"];
window.updateUnlocked = true;

const configureAppStore = (hash, namespace, state, reducers) => {
  let preloadedState = state ? { stateMachine: { ...state } } : {};

  const storageKey = `${hash}-${namespace.replace("/", "")}State`;

  if (window.localStorage.getItem(storageKey)) {
    preloadedState = JSON.parse(window.localStorage.getItem(storageKey));
    console.log('restore', preloadedState)
  }

  if (preloadedState?.stateMachine && !preloadedState?.stateMachine?.hash) {
    preloadedState["stateMachine"]["hash"] = hash;
  }

  if(!window._RDX){
    window._RDX = configureStore({
      reducer: reducers,
      preloadedState,
    });
  }

  const resetSession = () => {
    window.updateUnlocked = false;
    window.localStorage.removeItem(`${hash}-sessionID`);
    window.localStorage.removeItem(storageKey);
    window.location.reload();
  };

  window._RDX.subscribe(() => {
    if (window.updateUnlocked) {
      localStorage.setItem(storageKey, JSON.stringify(window._RDX.getState()));
    }
  });

  return window._RDX;
};

export { configureAppStore };
