import React, { useState } from "react";
import I18n from "../../../ui/I18n";
import end_qr_code from "../../../../images/end_game_qr_code.png";
import logo_decoration from "../../../../images/bell_logo_bottom_decoration.svg";

const Survey = () => {
  return (
    <div className="SurveyPage">
      <div className="SurveyPage__content">
        {" "}
        <I18n t="client.survey.content" />
      </div>
      <div className="SurveyPage__text">
        {" "}
        <I18n t="client.survey.text" />
      </div>

      <img className="SurveyPage__image" src={end_qr_code} alt="end_qr_code" />
    </div>
  );
};

export default Survey;
