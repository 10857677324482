import { setState } from "../stateMachine";
import I18n from "../../../ui/I18n";
import { useDispatch } from "react-redux";

const Info = () => {
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(setState("survey"));
  };
  return (
    <div className="InfoPage">
      <button
        className="InfoPage__decoration"
        onClick={handleClick}
      />
      <div className="InfoPage__content">
        <I18n t="client.rules_of_participation.content" />
      </div>
      <div className="InfoPage__text">
        <I18n t="client.rules_of_participation.text" />
      </div>
    </div>
  );
};

export default Info;
