import React from 'react';
import ReactDOM from 'react-dom';
import NoSleep from 'nosleep.js';
import Layout from './components/Layout';
import reportWebVitals from './configs/reportWebVitals';

import './stylesheets/app.scss';

//Screen sleep prevention instance
window._NO_SLEEP = new NoSleep();

window.addEventListener('hashchange', function() {
  window.location.reload()
}, false);

ReactDOM.render(
  <React.StrictMode>
    <Layout />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
