const DEFAULT_URL = process.env.REACT_APP_API_URL;

const contestStatus = async (hash) => {
  return await fetch(`${DEFAULT_URL}/status/${hash}`).then((res) =>
    res.json()
  );
};

const signup = async (hash, initials, bellCustomer) => {
  const round = window.localStorage.getItem(`${hash}-round`);

  const body = new FormData();
  body.append("initials", initials);
  body.append("bellCustomer", bellCustomer);
  body.append("round", round);

  // checks for the initials availability
  const availability = await fetch(
    `${DEFAULT_URL}/signup/${hash}/initalsAvailability`,
    {
      method: "POST",
      body
    }
  ).then((response) => response.json());

  if (availability?.allowed) {
    return fetch(`${DEFAULT_URL}/signup/${hash}`, {
      method: "POST",
      body
    }).then((response) => response.json());
  }

  return { error: "initials_taken" };
};

const uploadProfilePicture = async (hash, sessionID, payload) => {
  const { picture = null, profile = "default" } = payload;
  if (!sessionID) {
    return { error: "invalid_session" };
  }
  const body = new FormData();
  if (picture) {
    body.append("picture", picture);
  } else {
    body.append("profile", profile);
  }
  return await fetch(`${DEFAULT_URL}/signup/${hash}/${sessionID}`, {
    method: "PUT",
    body
  })
    .then((res) => res.json())
    .catch((e) => console.log(e));
};

const uploadEmail = async (hash, email) => {
  const body = new FormData();
  body.append("contest_email", email);

  return await fetch(`${DEFAULT_URL}/email/${hash}`, {
    method: "POST",
    body
  })
    .then((response) => response.json())
    .catch((e) => console.log(e));
};

export { contestStatus, signup, uploadProfilePicture, uploadEmail };
