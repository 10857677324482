import React, { useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setProfilePicture, setState } from "../../stateMachine";
import { uploadProfilePicture } from "../../../../../configs/api";
import TakeSelfie from "./TakeSelfie";

const ImageForm = () => {
  const hash = useSelector((state) => state.stateMachine.hash);
  const sessionID = useSelector(
    (state) => state.stateMachine.sessionID
  );
  const dispatch = useDispatch();
  const [image, setImage] = useState(null);
  const [imageBlob, setImageBlob] = useState(null);
  const [connecting, setConnecting] = useState(false);
  const [error, setError] = useState(null);
  let webcamRef = useRef(null);
  let payload = null;

  const imageBase64ToBlob = async (imageB64) => {
    if (!imageB64) {
      return;
    }
    return await fetch(imageB64).then((res) => res.blob());
  };

  const setRef = (ref) => (webcamRef = ref);

  const capture = (e) => {
    if (webcamRef) {
      const imageB64 = webcamRef.current.getScreenshot();
      imageBase64ToBlob(imageB64).then((imageBlob) => {
        window.tempImageBlob = imageBlob;
      });
      window.tempImageB64 = imageB64;
      setImage(imageB64);
      dispatch(setState("signup_selfie"));
    } else {
      setError("no_image_captured");
    }
  };

  const buttons = [
    {
      id: 1,
      title: "picture",
      isDefault: true
    },
    {
      id: 2,
      title: "avatar",
      isDefault: false
    }
  ];

  const defaultBtn = buttons.find((btn) => btn.isDefault === true);
  const [activeButtonId, setActiveButtonId] = useState(
    defaultBtn ? defaultBtn.id : null
  );
  const handleButtonClick = (e) => {
    dispatch(setState("signup_avatar"));
    setImageBlob(null);
  };
  return (
    <>
      <TakeSelfie
        error={error}
        setRef={setRef}
        buttons={buttons}
        activeButtonId={activeButtonId}
        handleButtonClick={handleButtonClick}
        capture={capture}
      />
    </>
  );
};

export default ImageForm;
