import React from "react";

const Waves = () => {
  return (
    <div className="waveContainer">
      <div className="wave3"></div>
      <div className="wave4"></div>
    </div>
  );
};

export default Waves;
