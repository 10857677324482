import { useSelector } from "react-redux";
import Page from "./Page";

const capitalize = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

const PageRedux = ({ children, hash, className = '' }) => {
  const step = useSelector((state) => state.stateMachine.step);

  className = [
    className,
    (hash && capitalize(hash)),
    (step && (capitalize(step)  + "Page"))
  ].join(' ');

  return (
    <Page className={className}>
      {children}
    </Page>
  )
}

export default PageRedux;
