import { useDispatch, useSelector } from "react-redux";
import { sendMessage, reviewPlayer } from "../stateMachine";

const Xicon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      enable-background="new 0 0 460.775 460.775"
      viewBox="0 0 460.775 460.775"
      width="288"
      height="288"
    >
      <path
        d="M285.08,230.397L456.218,59.27c6.076-6.077,6.076-15.911,0-21.986L423.511,4.565c-2.913-2.911-6.866-4.55-10.992-4.55
	c-4.127,0-8.08,1.639-10.993,4.55l-171.138,171.14L59.25,4.565c-2.913-2.911-6.866-4.55-10.993-4.55
	c-4.126,0-8.08,1.639-10.992,4.55L4.558,37.284c-6.077,6.075-6.077,15.909,0,21.986l171.138,171.128L4.575,401.505
	c-6.074,6.077-6.074,15.911,0,21.986l32.709,32.719c2.911,2.911,6.865,4.55,10.992,4.55c4.127,0,8.08-1.639,10.994-4.55
	l171.117-171.12l171.118,171.12c2.913,2.911,6.866,4.55,10.993,4.55c4.128,0,8.081-1.639,10.992-4.55l32.709-32.719
	c6.074-6.075,6.074-15.909,0-21.986L285.08,230.397z"
        fill="currentColor"
      ></path>
    </svg>
  );
};

const CheckIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      enable-background="new 0 0 460.775 460.775"
      viewBox="0 0 460.775 460.775"
      width="288"
      height="288"
    >
      <path
        d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z"
        fill="currentColor"
      ></path>
    </svg>
  );
};

const Players = () => {
  const dispatch = useDispatch();
  const players = useSelector((state) => state.stateMachine.players);
  const reviewedPlayers = useSelector(
    (state) => state.stateMachine.reviewedPlayers
  );

  const anonymize = (sessionID) => {
    dispatch(
      sendMessage({
        type: "anonymize",
        message: sessionID,
      })
    );
    dispatch(reviewPlayer(sessionID));
  };

  const review = (sessionID) => {
    dispatch(reviewPlayer(sessionID));
  };

  const renderPlayer = (player) => {
    const reviewed = reviewedPlayers.includes(player.userID);

    return (
      <div className="Players__item">
        {!reviewed && (
          <button
            className="Players__item-action Players__item-action--red"
            onClick={anonymize.bind(this, player.userID)}
            disabled={reviewed}
          >
            <Xicon />
          </button>
        )}
        <div className={`Players__item-name  ${reviewed ? 'reviewed' : ''}`}>{player.initials}</div>

        {!reviewed && (
          <button
            className="Players__item-action Players__item-action--green"
            onClick={review.bind(this, player.userID)}
            disabled={reviewed}
          >
            <CheckIcon />
          </button>
        )}
      </div>
    );
  };

  return (
    <div className="Players">
      <h5>Connected Players</h5>
      {players.map(renderPlayer)}
    </div>
  );
};

export default Players;
