import { useEffect, useState } from "react";
import correctIndicator from "../../images/winnerIndicator.png";
import incorrectIndicator from "../../images/loserIndicator.png";
import I18n from "./I18n";
import { useSelector } from "react-redux";

const AnswerIndicator = () => {
  const isCorrect = useSelector((state) => state.stateMachine.correct);
  const className = isCorrect !== null ? "__show" : "";
  const textClass = isCorrect ? "-text--winner" : "-text--loser";
  const imageClass = isCorrect == true ? "-img--winner" : "-img--loser";
  let image = isCorrect == true ? correctIndicator : incorrectIndicator;
  let text = isCorrect ? "right" : "wrong";

  return (
    <div className={`AnswerIndicator${className}`}>
      <img
        className={`AnswerIndicator${className}${imageClass}`}
        src={image}
      ></img>
      <div className={`AnswerIndicator${className}${textClass}`}>
        <I18n t={`client.game.indicator.${text}`} />
      </div>
    </div>
  );
};

export default AnswerIndicator;
